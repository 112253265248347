.appWrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.appContent {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}



